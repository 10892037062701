// Libs
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { AppDefaultProps, AppPropTypes } from "./App";

// Components
import AppLoader from "../components/AppLoader";
import Content from "../components/Content";

const MicroFrontend = ({ initialized, loading, ...props }) => {
  const { theme, ...propsToSend } = props;
  const loader = !initialized || loading ? <AppLoader /> : null;
  const content = initialized ? <Content {...propsToSend} /> : null;
  return initialized ? (
    <React.Fragment>
      {loader}
      {content}
    </React.Fragment>
  ) : (
    loader
  );
};

MicroFrontend.propTypes = {
  ...AppPropTypes,
  initialized: PropTypes.bool,
  loading: PropTypes.bool
};

MicroFrontend.defaultProps = {
  ...AppDefaultProps,
  initialized: false,
  loading: false
};

const mapStateToProps = state => ({
  loading: state.app.loading,
  initialized: state.app.initialized
});

export default connect(mapStateToProps)(withTranslation()(MicroFrontend));
